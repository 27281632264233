<template>
  <div class="animated fadeIn">

    <b-row>
      <b-col cols="12">

      <div class="card " >
        <div class="card-header">
          <strong>Import Data</strong>
        </div>

        <div class="card-body">
            <b-toast id="file-success" title="Upload Success" static no-auto-hide>
              File upload successful, import is running in the background.
            </b-toast>
            <div class="row">

              <div class="col-4">
                <p class="font-weight-bold">
                  Import Type
                </p>
                <b-form-select
                  id="input-3"
                  v-model="type"
                  :options="importoptions"
                ></b-form-select>
              </div>

              <div class="col">
                <p class="font-weight-bold">
                  File
                </p>

               <b-form-file
                  v-model="file1"
                  placeholder="Choose a file or drop it here..."
                  drop-placeholder="Drop file here..."
                ></b-form-file>
              </div>

            </div>

        </div>
        <div class="card-footer">
          <b-button class="float-right" :disabled="!validImportFields" @click="uploadFile" variant="success">Import</b-button>
        </div>
      </div>

      </b-col>

    </b-row><!--/.row-->

  </div>

</template>

<script>
  import axios from '../../../shared/axios/auth'
  import { setupCalendar, DatePicker } from 'v-calendar'
  import 'v-calendar/lib/v-calendar.min.css'

  setupCalendar({
    firstDayOfWeek: 2 // Monday
  })

  const today = new Date();
export default {
  components: {
      'v-date-picker': DatePicker
  },
  name: 'CustomerService',
  data: () => {
    return {
        file1: null,
        type: '',
        filename: '',
        upload_type: '',
        allowed_upload_types: ['xlsx', 'csv'],
        importoptions: [
          {value:'', text:'Please select'},
          {value:'new_business_pet', text:'New Business Pet Report'},
          {value:'new_business_sales', text:'New Business Sales Report'},
          {value:'cost_containment', text:'Cost Containment Pricelist'},
          {value:'sanctions_list', text:'Sanctions List (csv)'},
          /*{value:'davies_claim_file', text:'Davies Claim Export File (xlsx)'},*/
        ],
    }
  },
  methods: {
      uploadFile(){
        let url = '/reports/import/';
        let formData = new FormData();
        formData.append('import_type', this.type);
        formData.append('file_format', this.upload_type);
        formData.append('file', this.file1);
        axios.post(
          url,
          formData,
          {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
          }
        ).then((response) => {
          this.file1 = null;
          this.type = '';
          this.filename = '';
          this.upload_type = '';
          this.$bvToast.show('file-success');
        }).catch(error => {
          console.log("IMPORT", error)
          alert("Import error")
        });
      },
  },
  computed: {
      validImportFields: function(){
        if(this.file1 === null) return false;
        this.filename = this.file1.name;
        this.upload_type = this.filename.split('.').pop();
        return this.allowed_upload_types.includes(this.upload_type)
      },
  }
}
</script>
